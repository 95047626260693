<template>

  <b-row>
    <b-col md="6">
      <b-col md="12">
        <b-card
          no-body
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('Ürün Görseli Ekle') }}</span>
            </b-card-title>
          </b-card-header>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refImageObserver"
          >
            <b-form @submit.prevent="handleSubmit(addProductPhoto)">
              <validation-provider
                #default="validationContext"
                :name="$t('Görsel İsmi')"
                rules="required|min:2"
              >
                <b-form-group
                  :label="$t('Görsel İsmi')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="imageData.description"
                    autofocus
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                :name="$t('Ürün Görseli')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Ürün Görseli')"
                  label-for="image_file_path"
                >
                  <b-form-file
                    id="image_file_path"
                    v-model="imageData.file"
                    accept=".jpeg, .png, .jpg"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
                    :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  {{ $t('Görseli Yükle') }}
                </b-button>
              </div>

            </b-form>    </validation-observer> </b-card></b-col>
      <b-container
        fluid
        class="p-1 bg-dark"
      >
        <b-row>
          <b-col
            v-for="(item) in productImages"
            :id="item.id"
            :key="item.id"
            md="4"
            lg="4"
          >
            <b-card
              :img-src="item.image_url"
              :img-alt="item.description"
              img-top
            >
              <b-card-text>
                {{ item.description }}
              </b-card-text>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                size="sm"
                @click="deleteImage(item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <b-form-checkbox
                v-model="item.is_main_image"
                value="1"
                @click.native="changeMainImage(item.id)"
              >
                {{ $t('Ana Görsel') }}
              </b-form-checkbox>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-col>
    <b-col md="6">
      <b-col md="12">
        <b-row>
          <b-col md="12">
            <b-card
              no-body
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">{{ $t('Yeni Belge Ekle') }}</span>
                </b-card-title>
              </b-card-header>
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <b-form @submit.prevent="handleSubmit(addDocument)">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Dosya Açıklaması')"
                    rules="required|min:2"
                  >
                    <b-form-group
                      :label="$t('Dosya Açıklaması')"
                      label-for="description"
                    >
                      <b-form-input
                        id="description"
                        v-model="documentData.description"
                        autofocus
                        autocomplete="off"
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Dosya')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Dosya')"
                      label-for="file_path"
                    >
                      <b-form-file
                        id="file_path"
                        v-model="documentData.file"
                        accept=".jpeg, .png, .jpg, .pdf, .docx, .doc, .xls, .xlsx"
                        :state="getValidationState(validationContext)"
                        :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
                        :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Dosyayı Yükle') }}
                    </b-button>
                  </div>

                </b-form>    </validation-observer> </b-card></b-col></b-row></b-col>
      <b-col md="12">
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('Ürün Belgeleri') }}</span>
            </b-card-title>
          </b-card-header>
          <b-alert
            v-if="documents.length<=0"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ $t('Ürüne Yüklenmiş Belge Mevcut Değil') }}</span>
            </div>
          </b-alert>
          <b-table
            v-if="documents.length>0"
            striped
            responsive
            class="mb-0"
            :items="documents"
            :fields="documentsTableColumns"
          >
            <template #cell(description)="data">
              <a :href="data.item.file_url"> {{ data.value }}</a>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                size="sm"
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :href="data.item.file_url"
                  target="_blank"
                >
                  <feather-icon icon="ExternalLinkIcon" />
                  <span class="align-middle ml-50">  {{ $t('Görüntüle') }} </span>

                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  @click="deleteDocument(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50">{{ $t('Sil') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-col>
  </b-row>

</template>

<script>
import {
  BRow, BCol, BForm, BContainer, BFormCheckbox, BFormFile, BFormGroup, BDropdown, BDropdownItem, BFormInvalidFeedback, BFormInput, BButton, BAlert, BTable, BCard, BCardText, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BForm, BButton, BFormCheckbox, BCardText, BContainer, BFormFile, BDropdown, BDropdownItem, BFormInvalidFeedback, BFormGroup, BFormInput, BTable, BAlert, BCard, BCardHeader, BCardTitle, ValidationProvider, ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    changeMainImage(photoId) {
      store.dispatch('app-product/changeMainImage', { photo_id: photoId, product_id: this.productId })
        .then(() => {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.productImages.length; i++) {
            if (this.productImages[i].id !== photoId) {
              this.productImages[i].is_main_image = 0
            }
          }

          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    deleteImage(id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu ürün görseli silindiğinde geri getirilemeyecek. Yine de silmek istiyor musunuz ?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-product/deleteProductImage', { id })
            .then(() => {
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < this.productImages.length; i++) {
                if (this.productImages[i].id === id) {
                  this.productImages.splice(i, 1)
                }
              }

              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    deleteDocument(id) {
      let selectedData = null
      this.documents.forEach(val => {
        if (val.id === id) {
          selectedData = val
        }
      })
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu ürün dosyası silindiğinde geri getirilemeyecek. Yine de silmek istiyor musunuz ?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-product/deleteProductDocument', { id: selectedData.id })
            .then(() => {
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < this.documents.length; i++) {
                if (this.documents[i].id === selectedData.id) {
                  this.documents.splice(i, 1)
                }
              }

              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    addProductPhoto() {
      this.$refs.refImageObserver.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('image', this.imageData.file)
          formData.append('description', this.imageData.description)
          formData.append('product_id', this.imageData.product_id)
          store.dispatch('app-product/addProductImage', formData)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.$refs.refImageObserver.reset()
              this.productImages.unshift(response.data.data)

              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    addDocument() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('file', this.documentData.file)
          formData.append('description', this.documentData.description)
          formData.append('product_id', this.documentData.product_id)
          formData.append('file_lang', this.documentData.file_lang)
          store.dispatch('app-product/addProductDocument', formData)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.$refs.refFormObserver.reset()
              this.documents.unshift(response.data.data)

              this.resetDocumentData()
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
  },
  setup(props) {
    const blankDocumentData = {
      description: '',
      file: null,
      product_id: props.productId,
      file_lang: 'tr',

    }
    const blankImageData = {
      description: '',
      file: null,
      product_id: props.productId,
    }
    const imageData = ref(blankImageData)
    const documentData = ref(blankDocumentData)
    const productImages = ref([])
    const documents = ref([])
    const documentsTableColumns = [
      { key: 'description', label: i18n.t('Açıklama') },
      { key: 'created_at', label: i18n.t('Yükleme Zamanı') },
      { key: 'actions', label: i18n.t('İşlemler') },
    ]
    store.dispatch('app-product/fetchProductImages', { product_id: props.productId })
      .then(response => {
        productImages.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          productImages.value = undefined
        }
      })
    store.dispatch('app-product/fetchProductDocuments', { product_id: props.productId })
      .then(response => {
        documents.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          documents.value = undefined
        }
      })
    const onSubmit = () => {

    }
    const resetDocumentData = () => {
      documentData.value = JSON.parse(JSON.stringify(blankDocumentData))
    }
    const resetImageData = () => {
      imageData.value = JSON.parse(JSON.stringify(blankImageData))
    }
    resetImageData()
    resetDocumentData()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetDocumentData)
    const {
      refImageObserver,
    } = formValidation(resetImageData)
    // ? dataField is field name of `userDataSocial` object

    return {
      documentsTableColumns,
      documents,
      refFormObserver,
      getValidationState,
      documentData,
      resetDocumentData,
      refImageObserver,
      productImages,
      onSubmit,
      imageData,
    }
  },
}
</script>

<style>

</style>
