<template>
  <div>
    <!-- basic modal -->

    <b-form-group
      :label="$t('Eklenecek Ürün Adı Giriniz.')"
      label-for="product"
    >
      <vue-autosuggest
        :suggestions="productSuggest.filteredProducts"
        :input-props="productSuggest.inputProps"
        :value="productName"
        @selected="productSelected($event)"
        @input="productOnInputChanged"
      >
        <template slot-scope="{suggestion}">
          <div class="d-flex align-items-center">
            <b-img
              style="max-height:100px;"
              :src="suggestion.item.image"
            />
            <div class="detail ml-50">
              <b-card-text class="mb-0">
                {{ suggestion.item.name }}
              </b-card-text>
              <small class="text-muted">
                Liste Fiyatı: {{ $staticParams.formatCurrency(suggestion.item.list_price,4) }} {{ suggestion.item.currency_name }}
              </small>
            </div>
          </div>
        </template>
      </vue-autosuggest>
    </b-form-group>

    <hr>
    <!-- draggable -->
    <draggable
      v-model="localBlockData.items"
      class="list-group list-group-flush cursor-move"
      tag="ul"
    >

      <b-list-group-item
        v-for="(listItem,index) in localBlockData.items"
        :key="index"
        tag="li"
      >
        <div class="d-flex">
          <b-img
            style="max-height:100px;"
            :src="listItem.image"
          />
          <div class="ml-25">
            <b-card-text class="font-weight-bold mb-0">
              {{ listItem.name }} - {{ listItem.quantity }} adet df
            </b-card-text>

          </div>
        </div>
      </b-list-group-item>

    </draggable>
  </div>
</template>

<script>
import {

  BFormGroup, BCardText, BImg, BListGroupItem,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import store from '@/store'
import draggable from 'vuedraggable'

export default {
  components: {
    VueAutosuggest,
    BFormGroup,
    draggable,
    BImg,
    BListGroupItem,
    BCardText,
  },
  props: {
    productsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localBlockData: this.productsData,
      productName: '',
      quantitiy: 1,
      productSuggest: {
        limit: 10,
        filteredProducts: [],
        inputProps: {
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: "Ürün Adı Giriniz'",

        },

      },
    }
  },
  created() {

  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    productSelected(option) {
      const productItem = {
        id: option.item.id, name: option.item.name, image: option.item.image, quantity: 1,
      }
      let check = false
      this.localBlockData.forEach(val => {
        if (val.id === productItem.id) {
          check = true
        }
      })
      if (!check) {
        this.localBlockData.push(productItem)
      }
    },
    productOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestProducts', { name: text, limit: this.productSuggest.limit })
        .then(response => {
          this.productSuggest.filteredProducts = [{
            data: response.data.data,
          }]
        })
    },
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
